import { some, find } from "lodash";
import { useMutation, useQuery } from "@apollo/client";

import { ErrorPage, GithubForm, LoadingSpinner } from "../components";
import { GithubAuth } from "./integrations-auth";
import { useNotificationContext } from "../providers";
import { gql } from "../__generatedGQL__/gql";
import { RepoUpdateInput, ResourceType } from "../__generatedGQL__/graphql";
import { getEnvironment } from "../utils";

export const FIND_RESOURCE = gql(`
  query FindResource($type: ResourceType!) {
    resource(type: $type) {
      id
      externalId
      type
      userId
      createdAt
      updatedAt
    }
  }
`);

export const GET_REPOS = gql(`
  query GetRepos($resourceId: Int!) {
    repos(resourceId: $resourceId) {
      id
      name
      ownerName
      enabled
      lastSyncedAt
      config {
        blockedDirs
      }
      defaultBranch
      context
    }
  }
`);

const UPDATE_ENABLED_REPOS = gql(`
  mutation UpdateEnabledRepos($resourceId: Int!, $repoIds: [Int!]!) {
    updateEnabledRepos(resourceId: $resourceId, repoIds: $repoIds)
  }
`);

const SYNC_REPOS = gql(`
  mutation SyncRepos($resourceId: Int!) {
    syncRepos(resourceId: $resourceId)
  }
`);

const UPDATE_REPO = gql(`
  mutation UpdateRepo($id: Int!, $input: RepoUpdateInput!) {
    updateRepo(id: $id, input: $input) {
      id
    }
  }
`);

export const GithubSettings = () => {
  const { showNotification } = useNotificationContext();

  const {
    loading: loadingResources,
    error: resourcesError,
    data: resourcesData,
  } = useQuery(FIND_RESOURCE, {
    variables: {
      type: ResourceType.Github,
    },
  });
  const resource = resourcesData?.resource;
  const {
    loading: loadingRepos,
    error: reposError,
    data: reposData,
    refetch: refetchRepos,
  } = useQuery(GET_REPOS, {
    variables: {
      resourceId: resource?.id,
    },
    skip: !resource?.id,
  });

  const organizationName = reposData?.repos[0]?.ownerName;
  const githubEnabled = !!resource;

  const [updateEnabledReposMutation, { error: updateEnabledReposError }] =
    useMutation(UPDATE_ENABLED_REPOS);
  const [updateRepoMutation, { error: updateRepoError }] = useMutation(UPDATE_REPO);

  const updateRepo = async (id: number, input: RepoUpdateInput) => {
    await updateRepoMutation({ variables: { id, input } });
    await refetchRepos();
  };

  const updateEnabledRepos = async (repoIds: number[]) => {
    await updateEnabledReposMutation({ variables: { resourceId: resource?.id, repoIds } });
    await refetchRepos();

    const newReposEnabled = some(
      repoIds,
      (repoId) => !find(reposData.repos, (repo) => repo.id === repoId)?.enabled,
    );
    let message: string;
    if (newReposEnabled) {
      message =
        "Syncing may take up to 5 minutes. If you run into problems when creating an issue, please wait a few minutes before trying again.";
    }
    showNotification({
      title: "Updated synced repos",
      message,
    });
  };

  const [syncReposMutation, { error: syncReposError }] = useMutation(SYNC_REPOS);
  const syncRepos = async () => {
    await syncReposMutation({ variables: { resourceId: resource?.id } });
    await refetchRepos();
  };

  if (loadingResources || loadingRepos) return <LoadingSpinner />;

  const error = reposError || resourcesError || updateEnabledReposError || syncReposError;
  if (error)
    return (
      <ErrorPage
        errorCode="500"
        errorTitle="Error loading Github settings"
        errorDescription={error.message}
      />
    );

  return (
    <>
      {githubEnabled ? (
        <>
          <GithubForm
            organizationName={organizationName}
            repos={reposData.repos}
            updateEnabledRepos={updateEnabledRepos}
            syncRepos={syncRepos}
            updateRepo={updateRepo}
          />
        </>
      ) : (
        <div className="flex justify-start">
          <GithubAuth resource={resource} />
        </div>
      )}
    </>
  );
};
