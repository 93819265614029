import { isString } from "lodash";
import { useState } from "react";
import Markdown, { MarkdownToJSX } from "markdown-to-jsx";
import { ImageModal } from "./ImageModal";

interface IProps extends MarkdownToJSX.Options {
  children: string;
  onLinkClick?: (event: React.MouseEvent<HTMLAnchorElement>) => void;
  className?: string;
}

export const CustomMarkdown = ({ children, onLinkClick, className }: IProps) => {
  const [isImageModalOpen, setIsImageModalOpen] = useState(false);
  const [imageModalUrl, setImageModalUrl] = useState("");

  if (!children || !isString(children)) {
    console.error("CustomMarkdown: children is not a string", children);
    return null;
  }

  const handleImageClick = (src: string) => {
    setImageModalUrl(src);
    setIsImageModalOpen(true);
  };

  return (
    <>
      <Markdown
        options={{
          overrides: {
            a: ({ node, ...props }) => (
              <a
                {...props}
                target="_blank"
                rel="noopener noreferrer"
                onClick={(event) => {
                  if (onLinkClick) {
                    onLinkClick(event);
                  }
                }}
              />
            ),
            img: ({ src, alt, ...props }) => (
              <img
                src={src}
                alt={alt}
                {...props}
                className={`cursor-pointer ${props.className || ""}`}
                onClick={() => handleImageClick(src)}
              />
            ),
          },
        }}
        className={className}
      >
        {children}
      </Markdown>
      <ImageModal
        modalOpen={isImageModalOpen}
        setModalOpen={setIsImageModalOpen}
        imageUrl={imageModalUrl}
      />
    </>
  );
};
