import { useQuery, gql } from "@apollo/client";
import { useAppContext } from "../providers/AppProvider";
import { CopyText, ErrorPage } from "../components";
import { LoadingSpinner } from "../components/LoadingSpinner";
import { getInitials, getInitialsColor } from "../components/AppSidebar";

export const GET_USER_AND_CLIENT_DOMAIN = gql`
  query GetUserAndClientDomain($clientId: String!) {
    users {
      id
      name
      email
      createdAt
    }
    client(id: $clientId) {
      domain
    }
  }
`;

export const UserSettings = () => {
  const { selectedClientId } = useAppContext(); // Access selectedClientId from the global context

  // Use selectedClientId as a variable for the GraphQL query
  const { loading, error, data } = useQuery(GET_USER_AND_CLIENT_DOMAIN, {
    variables: { clientId: selectedClientId },
  });

  if (loading) return <LoadingSpinner />;
  if (error) {
    return (
      <ErrorPage
        errorCode="500"
        errorTitle="Error loading user settings"
        errorDescription={error.message}
      />
    );
  }

  return (
    <div>
      <div className="space-y-4">
        <div>
          <h2 className="text-lg font-semibold leading-7 text-gray-900">Team</h2>
          <p className="mt-1 max-w-3xl text-sm leading-6 text-gray-600">
            {data.client.domain ? (
              `Users who sign up via Google with a ${data.client.domain} domain will be added automatically.`
            ) : (
              <>
                Contact <CopyText text="founders@usetusk.ai" type="email" /> to add users to your
                organization.
              </>
            )}
          </p>
        </div>

        <table className="min-w-full divide-y divide-gray-300">
          <thead>
            <tr>
              <th
                scope="col"
                className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 w-1/3"
              >
                Name
              </th>
              <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                Email address
              </th>
              <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                Created at
              </th>
            </tr>
          </thead>
          <tbody className="bg-white divide-y divide-gray-200">
            {data.users.map((user) => (
              <tr key={user.id}>
                <td className="px-3 py-4 text-sm text-gray-500">
                  <div className="flex items-center">
                    <div
                      className={`h-8 w-8 rounded-full flex items-center justify-center text-white mr-2 ${getInitialsColor(
                        user.name,
                      )}`}
                    >
                      {getInitials(user.name)}
                    </div>
                    <span>{user.name}</span>
                  </div>
                </td>
                <td className="px-3 py-4 text-sm text-gray-500">{user.email}</td>
                <td className="px-3 py-4 text-sm text-gray-500">
                  {new Date(user.createdAt).toLocaleDateString("en-US", {
                    month: "short",
                    day: "2-digit",
                    year: "numeric",
                  })}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};
