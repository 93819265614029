import { GetAutoTriageCandidatesQuery } from "../__generatedGQL__/graphql";
import { XMarkIcon, ExclamationTriangleIcon } from "@heroicons/react/20/solid";
import { Button } from "./Button";
import { CustomMarkdown } from "./Markdown";
import { capitalize, isEmpty, map } from "lodash";
import { Fragment, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";

export const doesCandidateHaveEnoughInfo = (
  candidate: GetAutoTriageCandidatesQuery["autoTriageCandidates"][0],
) => {
  return candidate.hasDetailedDescription && isEmpty(candidate.clarifyingQuestions);
};

export const AutoTriageCandidateCard = ({
  candidate,
  onAssignToTusk,
  onDismiss,
}: {
  candidate: GetAutoTriageCandidatesQuery["autoTriageCandidates"][0];
  onAssignToTusk: () => Promise<void>;
  onDismiss: () => Promise<void>;
}) => {
  const [isDismissing, setIsDismissing] = useState(false);
  const [isAssigningToTusk, setIsAssigningToTusk] = useState(false);
  const [isConfirmAssignToTuskModalOpen, setIsConfirmAssignToTuskModalOpen] = useState(false);

  const assignToTuskWrapper = async () => {
    setIsAssigningToTusk(true);
    await onAssignToTusk();
    setIsAssigningToTusk(false);
  };

  const primaryAction = () => {
    if (doesCandidateHaveEnoughInfo(candidate)) {
      assignToTuskWrapper();
    } else {
      setIsConfirmAssignToTuskModalOpen(true);
    }
  };

  return (
    <>
      <li className="col-span-1 rounded-lg bg-white shadow relative flex flex-col">
        <button
          className="absolute -top-2 -right-2 p-1 bg-purple-50 rounded-full shadow-md text-purple-500 hover:bg-purple-100 hover:text-purple-600 transition-colors z-10 w-8 h-8 flex items-center justify-center"
          onClick={async () => {
            setIsDismissing(true);
            await onDismiss();
            setIsDismissing(false);
          }}
        >
          {isDismissing ? <DismissSpinner /> : <XMarkIcon className="h-4 w-4" aria-hidden="true" />}
        </button>
        <div className="flex-grow relative">
          <div className="flex w-full items-center justify-between space-x-6 pt-4 px-4">
            <div className="flex-1 min-w-0">
              <div className="flex items-center space-x-3 mb-2">
                <a
                  href={candidate.externalTicketUrl}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-sm font-medium text-gray-500 hover:text-purple-500 transition-colors"
                >
                  {candidate.externalTicketIdentifier}
                </a>
                {doesCandidateHaveEnoughInfo(candidate) ? (
                  <span className="inline-flex flex-shrink-0 items-center rounded-full bg-green-50 px-1.5 py-0.5 text-xs font-medium text-green-700 ring-1 ring-inset ring-green-600/20">
                    Sufficient info
                  </span>
                ) : (
                  <span className="inline-flex flex-shrink-0 items-center rounded-full bg-yellow-50 px-1.5 py-0.5 text-xs font-medium text-yellow-700 ring-1 ring-inset ring-yellow-600/20">
                    Needs more info
                  </span>
                )}
              </div>
              {/* TODO: Hide scrollbar, don't show gradient if there is no scroll needed */}
              <div className="h-[240px] overflow-y-auto pb-10">
                <div>
                  <h3 className="text-sm font-medium text-gray-900 break-words">
                    {candidate.title}
                  </h3>
                </div>
                <div className="mt-1 w-full text-sm text-gray-500 prose max-w-none prose-headings:my-0 prose-headings:text-sm prose-headings:font-medium overflow-wrap-anywhere">
                  <CustomMarkdown>{candidate.description}</CustomMarkdown>
                </div>
                {!isEmpty(candidate.clarifyingQuestions) && (
                  <div className="mt-3 font-sm">
                    <div className="relative mr-2 my-1">
                      <div aria-hidden="true" className="absolute inset-0 flex items-center">
                        <div className="w-full border-t border-gray-300 mr-4" />
                      </div>
                      <div className="relative flex justify-start">
                        <span className="bg-white pr-2 text-sm font-semibold text-gray-500">
                          Clarifying questions from Tusk
                        </span>
                      </div>
                    </div>
                    <ul className="space-y-1">
                      {map(candidate.clarifyingQuestions, (question) => (
                        <li className="text-sm text-gray-500 flex" key={question}>
                          <span className="mr-2 flex-shrink-0">•</span>
                          <span className="flex-1">{question}</span>
                        </li>
                      ))}
                    </ul>
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="absolute bottom-0 left-0 right-0 h-16 bg-gradient-to-t from-white to-transparent pointer-events-none"></div>
        </div>
        <div className="pb-4 px-4 flex justify-between items-center relative z-10">
          <div className="flex items-center text-xs text-gray-500">
            <p>
              {new Date(
                candidate.externalTicketCreatedAt || candidate.runCreatedAt,
              ).toLocaleDateString()}
            </p>
          </div>
          <div className="flex items-center">
            <Button
              className="mr-2"
              variant="secondary"
              size="xs"
              onClick={() => {
                window.open(candidate.externalTicketUrl, "_blank");
              }}
            >
              Go to {capitalize(candidate.resource?.type) || "ticket"}
            </Button>
            <Button variant="primary" size="xs" loading={isAssigningToTusk} onClick={primaryAction}>
              Assign to Tusk
            </Button>
          </div>
        </div>
      </li>
      <ConfirmAssignToTuskModal
        candidate={candidate}
        open={isConfirmAssignToTuskModalOpen}
        setOpen={setIsConfirmAssignToTuskModalOpen}
        onAssign={assignToTuskWrapper}
        isAssigning={isAssigningToTusk}
      />
    </>
  );
};

export const ConfirmAssignToTuskModal = ({
  candidate,
  open,
  setOpen,
  onAssign,
  isAssigning,
}: {
  candidate: GetAutoTriageCandidatesQuery["autoTriageCandidates"][0];
  open: boolean;
  setOpen: (open: boolean) => void;
  onAssign: () => Promise<void>;
  isAssigning: boolean;
}) => {
  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={setOpen}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-2xl sm:p-6">
                <div className="absolute right-0 top-0 hidden pr-4 pt-4 sm:block">
                  <button
                    className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-purple-500 focus:ring-offset-2"
                    onClick={() => setOpen(false)}
                  >
                    <span className="sr-only">Close</span>
                    <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                  </button>
                </div>
                <div className="sm:flex sm:items-start">
                  <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-yellow-100 sm:mx-0 sm:h-10 sm:w-10">
                    <ExclamationTriangleIcon
                      className="h-6 w-6 text-yellow-400"
                      aria-hidden="true"
                    />
                  </div>
                  <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left mr-4">
                    <Dialog.Title
                      as="h3"
                      className="text-base font-semibold leading-6 text-gray-900"
                    >
                      Issue needs more info
                    </Dialog.Title>
                    <div className="mt-2">
                      <p className="mt-2 text-sm text-gray-500">
                        Add more context to this issue before adding the{" "}
                        <span className="font-semibold">Tusk</span> label in{" "}
                        {capitalize(candidate.resource?.type)}.
                      </p>
                      <div className="mt-4 bg-gray-100 rounded-md p-4">
                        <div>
                          <h3 className="text-sm font-medium text-gray-900 break-words">
                            {candidate.title}
                          </h3>
                        </div>
                        <div className="mt-1 w-full text-sm text-gray-500 prose max-w-none prose-headings:my-0 prose-headings:text-sm prose-headings:font-medium">
                          <CustomMarkdown>{candidate.description}</CustomMarkdown>
                        </div>
                      </div>
                      <div>
                        {!isEmpty(candidate.clarifyingQuestions) && (
                          <div className="mt-5 font-sm">
                            <div className="relative mr-2 my-1">
                              <div
                                aria-hidden="true"
                                className="absolute inset-0 flex items-center"
                              >
                                <div className="w-full border-t border-gray-300 mr-4" />
                              </div>
                              <div className="relative flex justify-start">
                                <span className="bg-white pr-2 text-sm font-semibold text-gray-500">
                                  Clarifying questions from Tusk
                                </span>
                              </div>
                            </div>
                            <ul className="space-y-1">
                              {map(candidate.clarifyingQuestions, (question) => (
                                <li className="text-sm text-gray-500 flex" key={question}>
                                  <span className="mr-2 flex-shrink-0">•</span>
                                  <span className="flex-1">{question}</span>
                                </li>
                              ))}
                            </ul>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="mt-5 sm:mt-4 flex justify-end">
                  <Button
                    variant="secondary"
                    size="sm"
                    onClick={async () => {
                      await onAssign();
                      setOpen(false);
                    }}
                    loading={isAssigning}
                  >
                    Assign anyway
                  </Button>
                  <Button
                    className="ml-2"
                    variant="primary"
                    size="sm"
                    onClick={() => {
                      window.open(candidate.externalTicketUrl, "_blank");
                    }}
                  >
                    Go to {capitalize(candidate.resource?.type) || "ticket"}
                  </Button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

const DismissSpinner = () => {
  return (
    <svg
      className="h-4 w-4 animate-spin text-gray-600"
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
    >
      <circle
        className="opacity-25"
        cx="12"
        cy="12"
        r="10"
        stroke="currentColor"
        strokeWidth="4"
      ></circle>
      <path
        className="opacity-75"
        fill="currentColor"
        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
      ></path>
    </svg>
  );
};
