import { Fragment, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { ExclamationTriangleIcon, XMarkIcon } from "@heroicons/react/24/outline";
import { Button } from "./Button";
import { GetTasksQuery } from "../__generatedGQL__/graphql";
import { IconTooltip } from "./IconTooltip";

export const RetryGenerationModal = ({
  task,
  open,
  setOpen,
  onRetry,
}: {
  task?: GetTasksQuery["tasks"][0];
  open: boolean;
  setOpen: (open: boolean) => void;
  onRetry: (additionalContext: string) => Promise<void>;
}) => {
  const [isRetrying, setIsRetrying] = useState(false);
  const [additionalContext, setAdditionalContext] = useState("");

  if (!task) return null;

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={setOpen}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                <div className="absolute right-0 top-0 hidden pr-4 pt-4 sm:block">
                  <button
                    className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-purple-500 focus:ring-offset-2"
                    onClick={() => setOpen(false)}
                  >
                    <span className="sr-only">Close</span>
                    <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                  </button>
                </div>
                <div className="sm:flex sm:items-start sm:pr-4">
                  <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-purple-100 sm:mx-0 sm:h-10 sm:w-10">
                    <ExclamationTriangleIcon
                      className="h-6 w-6 text-purple-600"
                      aria-hidden="true"
                    />
                  </div>
                  <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                    <Dialog.Title
                      as="h3"
                      className="text-base font-semibold leading-6 text-gray-900 flex"
                    >
                      Retry generation
                      <IconTooltip
                        tooltipText={`Any open PRs associated with this issue will be closed and the branches deleted.${
                          task.externalTicketId
                            ? ` Any comments added to the external ticket will be deleted.`
                            : ""
                        }`}
                        tooltipClassName="!w-[240px] mt-2"
                      />
                    </Dialog.Title>
                    <div className="mt-4">
                      <p className="text-sm text-gray-500 text-left">
                        Are you sure you want to retry generation for this task (
                        <span className="font-semibold">"{task.metadata.title}"</span>)?
                      </p>
                      <div className="mt-4">
                        <div className="flex justify-between">
                          <label
                            htmlFor="additional-context"
                            className="block text-sm text-left font-medium text-gray-700"
                          >
                            Additional context
                          </label>
                        </div>
                        <textarea
                          id="additional-context"
                          placeholder="Enter additional issue details (optional)"
                          className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-purple-500 focus:ring-purple-500 sm:text-sm"
                          rows={2}
                          onChange={(e) => setAdditionalContext(e.target.value)}
                        />
                      </div>
                      {task.externalTicketId && (
                        <p className="mt-2 text-sm text-left text-gray-500">
                          The latest ticket details will be used along with any additional context.
                        </p>
                      )}
                    </div>
                  </div>
                </div>
                <div className="mt-5 sm:mt-6 sm:pr-4 sm:flex sm:flex-row-reverse">
                  <Button
                    className="bg-purple-600 text-white shadow-sm hover:bg-purple-500 sm:ml-3 sm:w-auto"
                    onClick={async () => {
                      setIsRetrying(true);
                      await onRetry(additionalContext);
                      setIsRetrying(false);
                      setOpen(false);
                    }}
                    loading={isRetrying}
                  >
                    Retry
                  </Button>
                  <Button variant="secondary" onClick={() => setOpen(false)}>
                    Cancel
                  </Button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};
