import { isNumber, toString } from "lodash";
import { CopyText } from "../components";
import { useAppContext } from "../providers";
import { Client } from "../__generatedGQL__/graphql";
import { Button } from "../components";

export const FREE_PLAN = "Free";
export const FREE_PLAN_MAX_SUCCESSFUL_TASKS = 3;
export const FREE_PLAN_MAX_SYNCED_REPOS = 1;

export const isMonthlyPlan = (subscriptionPlan: Client["subscriptionPlan"]) => {
  return isNumber(subscriptionPlan?.pricePerMonth);
};

export const isPaidPlan = (subscriptionPlan: Client["subscriptionPlan"]) => {
  return subscriptionPlan?.planName && subscriptionPlan?.planName !== FREE_PLAN;
};

export const getMaxSuccessfulTasks = (subscriptionPlan: Client["subscriptionPlan"]): number => {
  if (isPaidPlan(subscriptionPlan)) {
    return subscriptionPlan?.maxSuccessfulTasks || Infinity;
  } else {
    return isNumber(subscriptionPlan?.maxSuccessfulTasks)
      ? subscriptionPlan?.maxSuccessfulTasks
      : FREE_PLAN_MAX_SUCCESSFUL_TASKS;
  }
};

export const getMaxSyncedRepos = (subscriptionPlan: Client["subscriptionPlan"]): number => {
  return isNumber(subscriptionPlan?.maxSyncedRepos)
    ? subscriptionPlan?.maxSyncedRepos
    : FREE_PLAN_MAX_SYNCED_REPOS;
};

export const getPlanName = (subscriptionPlan: Client["subscriptionPlan"]) => {
  return subscriptionPlan?.planName || FREE_PLAN;
};

export const SubscriptionSettings = () => {
  const { selectedClient } = useAppContext();

  return (
    <div className="space-y-12 sm:space-y-16">
      <div>
        <h2 className="text-lg font-semibold leading-7 text-gray-900">Subscription</h2>
        <p className="mt-1 text-sm leading-6 text-gray-600">
          Contact <CopyText text="founders@usetusk.ai" type="email" /> to upgrade to Enterprise or
          an annual plan.
        </p>
        <div className="mt-6 bg-white border-t border-gray-100 shadow sm:rounded-lg">
          <div className="px-4 py-5 sm:p-6">
            <h3 className="text-base font-semibold leading-6 text-gray-900">
              {getPlanName(selectedClient?.subscriptionPlan)}
            </h3>
            <div className="mt-2 sm:flex sm:items-start sm:justify-between">
              <div className="mt-2 max-w-xxl text-sm text-gray-500">
                <p>Your organization has up to:</p>
                <p>
                  {getMaxSuccessfulTasks(selectedClient?.subscriptionPlan) === Infinity
                    ? "Unlimited"
                    : getMaxSuccessfulTasks(selectedClient?.subscriptionPlan)}
                  {isNumber(selectedClient?.subscriptionPlan?.pricePerMonth) ? " per month" : ""}{" "}
                  merged pull requests and{" "}
                  {getMaxSyncedRepos(selectedClient?.subscriptionPlan) === 1
                    ? "1 synced repository"
                    : `${getMaxSyncedRepos(selectedClient?.subscriptionPlan)} synced repositories`}
                </p>
              </div>
              <div className="mt-5 sm:ml-6 sm:mt-0 sm:flex sm:flex-shrink-0 sm:items-center">
                <Button
                  link="https://buy.stripe.com/bIY5oi4wgg74fkI00e?prefilled_promo_code=PHLAUNCH24"
                  disabled={
                    getPlanName(selectedClient?.subscriptionPlan) === "Team Plan" ||
                    getPlanName(selectedClient?.subscriptionPlan) === "Enterprise Plan"
                  }
                >
                  Upgrade plan
                </Button>
              </div>
            </div>
          </div>
        </div>
        {/* <dl className="divide-y divide-gray-100">
            <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
              <dt className="text-sm font-medium leading-6 text-gray-900">Plan</dt>
              <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                {getPlanName(selectedClient?.subscriptionPlan)}
              </dd>
            </div>
            <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
              <dt className="text-sm font-medium leading-6 text-gray-900">
                Generated Pull Requests/Branches
              </dt>
              <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                {getMaxSuccessfulTasks(selectedClient?.subscriptionPlan) === Infinity
                  ? "unlimited"
                  : getMaxSuccessfulTasks(selectedClient?.subscriptionPlan)}
                {isNumber(selectedClient?.subscriptionPlan?.pricePerMonth) ? " / month" : ""}
              </dd>
            </div>
            <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
              <dt className="text-sm font-medium leading-6 text-gray-900">Synced Repos</dt>
              <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                {getMaxSyncedRepos(selectedClient?.subscriptionPlan)}
              </dd>
            </div>
          </dl> */}
      </div>
    </div>
  );
};
