import { BoltIcon, DocumentTextIcon } from "@heroicons/react/20/solid";
import { Button } from "./Button";
import { CustomMarkdown } from "./Markdown";

interface IProps {
  onNewIssueClick?: () => void;
  buttonDisabled?: boolean;
  buttonTooltip?: string;
  title: string;
  description: string;
  additionalText?: string;
  buttonText?: string;
}

export const IssueEmptyState = ({
  onNewIssueClick,
  buttonDisabled = false,
  buttonTooltip = "",
  title,
  description,
  additionalText,
  buttonText,
}: IProps) => {
  return (
    <div className="text-center px-48 py-16 rounded-lg border border-gray-300">
      {/* <svg
        className="mx-auto h-12 w-12 text-gray-400"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
        aria-hidden="true"
      >
        <path
          vectorEffect="non-scaling-stroke"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={2}
          d="M9 13h6m-3-3v6m-9 1V7a2 2 0 012-2h6l2 2h6a2 2 0 012 2v8a2 2 0 01-2 2H5a2 2 0 01-2-2z"
        />
      </svg> */}
      <DocumentTextIcon className="mx-auto h-12 w-12 text-gray-400" />
      <h3 className="mt-2 mb-2 text-sm font-semibold text-gray-900">{title}</h3>
      <CustomMarkdown className="text-sm text-gray-500">{description}</CustomMarkdown>
      <p className="text-sm text-gray-500">{additionalText}</p>
      <div className="mt-6">
        <Button
          size="md"
          type="button"
          onClick={onNewIssueClick}
          disabled={buttonDisabled}
          tooltipText={buttonTooltip}
        >
          {/* <BoltIcon className="-ml-0.5 mr-1.5 h-5 w-5" aria-hidden="true" /> */}
          {buttonText}
        </Button>
      </div>
    </div>
  );
};
