import { Fragment, useState, useEffect } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/24/outline";
import { Button } from "./Button";
import { trim } from "lodash";

interface CustomInstructionsModalProps {
  isOpen: boolean;
  onClose: () => void;
  repoName: string;
  currentInstructions?: string | null;
  onUpdateInstructions: (instructions: string) => Promise<void>;
}

export const CustomInstructionsModal: React.FC<CustomInstructionsModalProps> = ({
  isOpen,
  onClose,
  repoName,
  currentInstructions,
  onUpdateInstructions,
}) => {
  const [instructions, setInstructions] = useState(currentInstructions || "");
  const [isSaving, setIsSaving] = useState(false);

  const handleSave = async () => {
    setIsSaving(true);
    await onUpdateInstructions(instructions);
    setIsSaving(false);
  };

  return (
    <Transition.Root show={isOpen} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={onClose}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white py-4 px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-3xl sm:p-6">
                <div className="absolute right-0 top-0 hidden pr-4 pt-4 sm:block">
                  <button
                    type="button"
                    className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-purple-500 focus:ring-offset-2"
                    onClick={onClose}
                  >
                    <span className="sr-only">Close</span>
                    <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                  </button>
                </div>
                <div className="sm:flex sm:items-start">
                  <div className="mt-3 text-center sm:ml-px sm:mt-0 sm:text-left">
                    <Dialog.Title
                      as="h3"
                      className="text-base font-semibold leading-6 text-gray-900"
                    >
                      Custom instructions
                    </Dialog.Title>
                    <div className="mt-2">
                      <p className="text-sm text-gray-500">
                        Provide custom repo context for{" "}
                        <span className="font-semibold">{repoName}</span>
                      </p>
                    </div>
                  </div>
                </div>
                <div className="mt-2">
                  <textarea
                    className="mt-2 p-2 w-full border rounded-md text-sm focus:ring-purple-500 focus:border-purple-500"
                    rows={14}
                    value={instructions}
                    onChange={(e) => setInstructions(e.target.value)}
                    placeholder={`This is a monorepo for ACME Inc, an app that helps consumer software companies create more widgets faster.\n\nThe frontend is TypeScript, React, and Tailwind CSS. The backend is Node.js, Express, and PostgreSQL.The component library uses shadcn components and is located in the \`src/components\` folder.`}
                  />
                </div>
                <div className="mt-5 sm:mt-4 sm:flex sm:justify-end">
                  <Button size="md" variant="secondary" onClick={onClose} className="mr-3">
                    Cancel
                  </Button>
                  <Button
                    size="md"
                    loading={isSaving}
                    onClick={handleSave}
                    disabled={
                      instructions.length === 0 || trim(instructions) === trim(currentInstructions)
                    }
                  >
                    Save
                  </Button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};
