import { useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";

import { gql } from "../__generatedGQL__/gql";
import { useMutation, useQuery } from "@apollo/client";
import { PageHeading, LoadingSpinner, ErrorPage } from "../components";
import { useAppContext, useNotificationContext } from "../providers";
import { isEmpty, map, sortBy } from "lodash";
import {
  AutoTriageCandidateCard,
  doesCandidateHaveEnoughInfo,
} from "../components/AutoTriageCandidateCard";
import { getAppUrl } from "../utils";
import { AutoTriageEmptyState } from "../components/AutoTriageEmptyState";
import { useExternalTicketingHook } from "../hooks/useExternalTicketingHook";

const GET_AUTO_TRIAGE_CANDIDATES = gql(`
query GetAutoTriageCandidates {
  autoTriageCandidates {
    runId
    runCreatedAt
    source

    title
    description
    images

    externalTicketIdentifier
    externalTicketUrl
    externalTicketCreatedAt

    hasDetailedDescription
    clarifyingQuestions

    resource {
      type
    }
  }
}`);

const DISMISS_AUTO_TRIAGE_CANDIDATE = gql(`
  mutation DismissAutoTriageCandidate($autoTriageRunId: String!) {
    dismissAutoTriageCandidate(autoTriageRunId: $autoTriageRunId)
  }
`);

const CREATE_TASK_FROM_AUTO_TRIAGE_CANDIDATE = gql(`
  mutation CreateTaskFromAutoTriageCandidate($autoTriageRunId: String!) {
    createTaskFromAutoTriageCandidate(autoTriageRunId: $autoTriageRunId) {
      id
    }
  }
`);

export const AutoTriagePage = () => {
  const { user, selectedClientId } = useAppContext();
  const { showNotification } = useNotificationContext();

  const { loading, error, data, refetch } = useQuery(GET_AUTO_TRIAGE_CANDIDATES, {
    fetchPolicy: "cache-and-network",
  });
  const [dismissAutoTriageCandidate] = useMutation(DISMISS_AUTO_TRIAGE_CANDIDATE);
  const [createTaskFromAutoTriageCandidate] = useMutation(CREATE_TASK_FROM_AUTO_TRIAGE_CANDIDATE);

  const { isExternalTicketingConnected } = useExternalTicketingHook();

  const sortedAutoTriageCandidates = useMemo(() => {
    if (!data) return [];
    return sortBy(
      data.autoTriageCandidates,
      (candidate) => !doesCandidateHaveEnoughInfo(candidate),
    );
  }, [data, doesCandidateHaveEnoughInfo]);

  if (loading && !data) return <LoadingSpinner />;
  if (error)
    return (
      <ErrorPage
        errorCode="500"
        errorTitle="Error loading auto triage page"
        errorDescription={error.message}
      />
    );

  // TODO: Pass in isExternalTicketingConnected after fetching from API

  return (
    <div>
      <PageHeading
        name="Suggested Issues"
        subheader={
          <p className="mt-4 text-sm text-gray-700">
            Tusk auto-surfaces issues from your project management app that are suitable for it to
            create a pull request for.
          </p>
        }
      />
      {isEmpty(data.autoTriageCandidates) && (
        <div className="mt-6">
          <AutoTriageEmptyState isExternalTicketingConnected={isExternalTicketingConnected} />
        </div>
      )}
      <ul role="list" className="grid grid-cols-1 gap-6 md:grid-cols-2 xl:grid-cols-3">
        {map(sortedAutoTriageCandidates, (candidate) => (
          <AutoTriageCandidateCard
            key={candidate.runId}
            candidate={candidate}
            onAssignToTusk={async () => {
              try {
                const { data } = await createTaskFromAutoTriageCandidate({
                  variables: { autoTriageRunId: candidate.runId },
                });
                await refetch();
                showNotification({
                  type: "success",
                  title: `Assigned ${candidate.externalTicketIdentifier || "issue"} to Tusk 🎉`,
                  message: `Tusk will create a pull request for the issue in about 15 minutes. Check the [activity log](${getAppUrl()}/app/task/${
                    data.createTaskFromAutoTriageCandidate.id
                  }?client=${selectedClientId}) for details.`,
                });
              } catch (error) {
                console.error(error);
                showNotification({
                  type: "error",
                  title: `Error assigning ${candidate.externalTicketIdentifier || "issue"} to Tusk`,
                  message: error.message,
                });
              }
            }}
            onDismiss={async () => {
              try {
                await dismissAutoTriageCandidate({
                  variables: { autoTriageRunId: candidate.runId },
                });
                await refetch();
                showNotification({
                  type: "success",
                  title: `Dismissed ${candidate.externalTicketIdentifier || "issue"}`,
                });
              } catch (error) {
                console.error(error);
                showNotification({
                  type: "error",
                  title: `Error dismissing ${candidate.externalTicketIdentifier || "ticket"}`,
                  message: error.message,
                });
              }
            }}
          />
        ))}
      </ul>
    </div>
  );
};
