import { useEffect, useMemo, useState } from "react";
import { useMutation, useQuery } from "@apollo/client";
import { filter, isNumber, find } from "lodash";
import { startOfMonth, endOfMonth } from "date-fns";
import { useNavigate } from "react-router-dom";

import {
  IssuesTable,
  PageHeading,
  CreateIssueModal,
  LoadingSpinner,
  ScheduleOnboardingModal,
  ErrorPage,
} from "../components";
import { FIND_RESOURCE, GET_REPOS } from "../containers/GithubSettings";
import { gql } from "../__generatedGQL__/gql";
import { ResourceType, TaskInput, TaskSource } from "../__generatedGQL__/graphql";
import { useAppContext } from "../providers";
import { isMonthlyPlan } from "../containers";
import { useExternalTicketingHook } from "../hooks/useExternalTicketingHook";

const GET_TASKS = gql(`
  query GetTasks {
    tasks {
      id
      source
      metadata {
        title
        description
        disablePullRequestCreation
      }
      externalTicketId
      externalTicketMetadata {
        identifier
        url
      }
      user {
        id
        name
      }
      repo {
        name
        ownerName
        defaultBranch
      }
      activeGeneratePullRequestRun {
        id
        branchName
        status
        activePullRequest {
          id
          isReadyForReview
          externalUrl
          status
          activeTestingRun {
            id
            dynamicEnvUrl
            status
            videoUrl
            createdAt
            updatedAt
          }
          activeAddressCodeReviewRun {
            id
            status
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      activeInvestigationRun {
        id
        status
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`);

const GET_TASK_STATS = gql(`
  query GetTaskStats($startDate: DateTime, $endDate: DateTime) {
    taskStats(startDate: $startDate, endDate: $endDate) {
      successfulGeneratePullRequestRuns
    }
  }
`);

const CREATE_TASK = gql(`
  mutation CreateTask($task: TaskInput!) {
    createTask(task: $task) {
      id
    }
  }
`);

const RETRY_TASK = gql(`
  mutation RetryTask($id: String!, $additionalContext: String) {
    retryTask(id: $id, additionalContext: $additionalContext)
  }
`);

const TEST_TASK = gql(`
  mutation TestTask($pullRequestId: String!) {
    testTask(pullRequestId: $pullRequestId)
  }
`);

const DELETE_TASK = gql(`
  mutation DeleteTask($id: String!) {
    deleteTask(id: $id)
  }
`);

const CREATE_PULL_REQUEST = gql(`
  mutation CreatePullRequest($taskId: String!) {
    createPullRequest(taskId: $taskId) {
      id
      externalUrl
    }
  }
`);

export const DashboardPage = () => {
  const { user, selectedClientId, selectedClient } = useAppContext();

  const navigate = useNavigate();

  const {
    loading: loadingTasks,
    error: tasksError,
    data,
    refetch: refetchTasks,
  } = useQuery(GET_TASKS, { pollInterval: 10000 });
  const {
    loading: loadingTaskStats,
    error: taskStatsError,
    data: taskStatsData,
  } = useQuery(GET_TASK_STATS, {
    pollInterval: 100000,
    ...(isMonthlyPlan(selectedClient?.subscriptionPlan)
      ? {
          variables: {
            startDate: startOfMonth(new Date()),
            endDate: endOfMonth(new Date()),
          },
        }
      : {}),
  });

  const {
    loading: githubLoadingResources,
    error: githubResourcesError,
    data: githubResourcesData,
  } = useQuery(FIND_RESOURCE, {
    variables: {
      type: ResourceType.Github,
    },
  });
  const resource = githubResourcesData?.resource;
  const {
    loading: loadingRepos,
    error: reposError,
    data: reposData,
    refetch: refetchRepos,
  } = useQuery(GET_REPOS, {
    variables: {
      resourceId: resource?.id,
    },
    skip: !resource?.id,
    fetchPolicy: "cache-and-network",
  });
  const enabledRepos = useMemo(() => {
    if (!reposData?.repos) return [];
    return filter(reposData.repos, (repo) => repo.enabled);
  }, [reposData?.repos]);

  const {
    isExternalTicketingConnected,
    loading: isLoadingExternalTicketing,
    error: isErrorExternalTicketing,
  } = useExternalTicketingHook();

  const showConnectExternalTicketingBanner =
    !isLoadingExternalTicketing &&
    !isErrorExternalTicketing &&
    !isExternalTicketingConnected &&
    !!resource;

  const [createTaskMutation, { loading: creatingTask }] = useMutation(CREATE_TASK);
  const [retryTaskMutation, { loading: retryingTask }] = useMutation(RETRY_TASK);
  const [testTaskMutation, { loading: testingTask }] = useMutation(TEST_TASK);
  const [deleteTaskMutation, { loading: deletingTask }] = useMutation(DELETE_TASK);
  const [createPullRequestMutation, { loading: creatingPullRequest }] =
    useMutation(CREATE_PULL_REQUEST);

  const [issueModalOpen, setIssueModalOpen] = useState(false);

  useEffect(() => {
    const sessionStorageKey = `${selectedClientId}.${user.id}.shownOnboardingModal`;
    const shownOnboardingModal = sessionStorage.getItem(sessionStorageKey);
    if (!githubLoadingResources && !resource && !shownOnboardingModal) {
      navigate("/app/settings/integrations");
    }
  }, [githubLoadingResources, resource]);

  const loading = loadingTasks || githubLoadingResources || loadingRepos || loadingTaskStats;

  // Only show error if we have no data (for polling queries)
  const error =
    (!data && tasksError) ||
    githubResourcesError ||
    reposError ||
    (!taskStatsData && taskStatsError);

  const filteredTasks = useMemo(() => {
    if (!data?.tasks) return [];
    if (!user.isAdmin) {
      // If user is not admin, only show auto-triaged tasks that have a pull request and non-auto-triaged tasks
      // This is because auto-triaged tasks are created automatically and admin users will create pull request if it is reasonable
      return data.tasks.filter(
        (task) =>
          (task.source === TaskSource.AutoTriage &&
            task.activeGeneratePullRequestRun?.activePullRequest) ||
          task.source !== TaskSource.AutoTriage,
      );
    }
    return data.tasks;
  }, [data?.tasks, user]);

  if (loading) return <LoadingSpinner />;
  if (error)
    return (
      <ErrorPage
        errorCode="500"
        errorTitle="Error loading dashboard"
        errorDescription={error.message}
      />
    );

  const createTask = async (task: TaskInput) => {
    const { data } = await createTaskMutation({ variables: { task } });
    await refetchTasks();
    return data.createTask;
  };

  const retryTask = async (id: string, additionalContext?: string) => {
    await retryTaskMutation({ variables: { id, additionalContext } });
    await refetchTasks();
  };

  const testTask = async (pullRequestId: string) => {
    await testTaskMutation({ variables: { pullRequestId } });
    await refetchTasks();
  };

  const deleteTask = async (id: string) => {
    await deleteTaskMutation({ variables: { id } });
    await refetchTasks();
  };

  const createPullRequestFromTask = async (taskId: string): Promise<string> => {
    const mutationOutput = await createPullRequestMutation({ variables: { taskId } });
    await refetchTasks();
    return mutationOutput.data.createPullRequest.externalUrl;
  };

  const welcomeMessage =
    user && user.name && user.name.trim() !== "" && !user.name.includes("@")
      ? `Welcome to Tusk, ${user.name.split(" ")[0]}`
      : "Welcome to Tusk";

  return (
    <div>
      <PageHeading name={welcomeMessage} />
      <CreateIssueModal
        open={issueModalOpen}
        setOpen={setIssueModalOpen}
        createTask={createTask}
        creatingTask={creatingTask}
        repos={enabledRepos}
      />
      <IssuesTable
        showConnectExternalTicketingBanner={showConnectExternalTicketingBanner}
        taskStats={taskStatsData?.taskStats}
        tasks={filteredTasks}
        openModal={() => setIssueModalOpen(true)}
        retryTask={retryTask}
        testTask={testTask}
        deleteTask={deleteTask}
        createPullRequestFromTask={createPullRequestFromTask}
        repos={enabledRepos}
      />
    </div>
  );
};
