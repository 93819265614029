import { createElement, useMemo, useState, useEffect, useCallback } from "react";
import {
  CircleStackIcon,
  PencilIcon,
  MagnifyingGlassCircleIcon,
  BookOpenIcon,
  CodeBracketSquareIcon,
  CommandLineIcon,
  LightBulbIcon,
  GlobeAmericasIcon,
  CursorArrowRippleIcon,
  ExclamationCircleIcon,
  PencilSquareIcon,
  CheckIcon,
  ArrowUpOnSquareIcon,
  ChevronRightIcon,
  ChevronDownIcon,
  PhotoIcon,
  FolderIcon,
  TicketIcon,
  LinkIcon,
  CpuChipIcon,
  DocumentMagnifyingGlassIcon,
  RectangleGroupIcon,
  VideoCameraIcon,
  CogIcon,
  ClipboardDocumentListIcon,
  ClipboardDocumentCheckIcon,
  DocumentDuplicateIcon,
  DocumentTextIcon,
  EyeIcon,
  ComputerDesktopIcon,
} from "@heroicons/react/20/solid";
import {
  size,
  includes,
  isEmpty,
  map,
  join,
  split,
  replace,
  filter,
  find,
  sortBy,
  isString,
  flatMap,
} from "lodash";
import { clsx } from "clsx";

import {
  ImageModal,
  HUMAN_READABLE_TASK_TYPES,
  AgentLogsList,
  CustomMarkdown,
} from "../components";
import { useAppContext } from "../providers";
import {
  AddressCodeReviewRunStatus,
  ClientFeatures,
  GeneratePullRequestRunStatus,
  GetTaskWithAllRunsQuery,
  InvestigationRunStatus,
  TestingRunStatus,
} from "../__generatedGQL__/graphql";
import { getAppUrl, getGeneratePullRequestRunBranchUrl, getNumberWithOrdinal } from "../utils";
import { IssueStatus } from "./IssuesTable";
import { TaskStatus } from "../utils/taskUtils";

// TODO: Don't have this hardcoded
const codeToolNameMap = {
  read_pull_request_review: { humanReadableName: "Read pull request review", icon: BookOpenIcon },
  infer_issue_type: { humanReadableName: "Inferred issue type", icon: TicketIcon },
  image_analysis: { humanReadableName: "Analyzed images", icon: PhotoIcon },
  video_analysis: { humanReadableName: "Analyzed videos", icon: VideoCameraIcon },
  determine_repo: { humanReadableName: "Determined repo", icon: FolderIcon },
  external_link_analysis: { humanReadableName: "Analyzed external link", icon: LinkIcon },
  external_link_analysis_error: {
    humanReadableName: "Error analyzing external link",
    icon: ExclamationCircleIcon,
  },
  use_merged_pr_memory: { humanReadableName: "Found similar pull requests", icon: CpuChipIcon },
  initial_exploration: {
    humanReadableName: "Explored codebase",
    icon: DocumentMagnifyingGlassIcon,
  },
  initial_component_library_exploration: {
    humanReadableName: "Explored component library",
    icon: RectangleGroupIcon,
  },
  update_plan: { humanReadableName: "Updated plan", icon: PencilIcon },
  query_vector_database: { humanReadableName: "Searched for relevant code", icon: CircleStackIcon },
  search_file_paths: { humanReadableName: "Searched file paths", icon: MagnifyingGlassCircleIcon },
  search_code: { humanReadableName: "Searched code", icon: MagnifyingGlassCircleIcon },
  read_file: { humanReadableName: "Read file", icon: BookOpenIcon },
  update_high_level_updates: { humanReadableName: "Updated coding instructions", icon: PencilIcon },
  finish_high_level_updates: {
    humanReadableName: "Finished coding instructions",
    icon: PencilIcon,
  },
  update_code_investigation_notes: {
    humanReadableName: "Updated investigation notes",
    icon: PencilIcon,
  },
  finish_code_investigation_notes: {
    humanReadableName: "Finished investigation notes",
    icon: PencilIcon,
  },
  generate_file: { humanReadableName: "Generated file", icon: CodeBracketSquareIcon },
  create_pull_request: { humanReadableName: "Created pull request", icon: CommandLineIcon },
  update_pull_request: { humanReadableName: "Updated pull request", icon: CommandLineIcon },
  create_branch: { humanReadableName: "Created branch", icon: CommandLineIcon },
  update_branch: { humanReadableName: "Updated branch", icon: CommandLineIcon },
  run_external_check: { humanReadableName: "Running checks", icon: CommandLineIcon },
  read_error_logs: { humanReadableName: "Read error logs", icon: ExclamationCircleIcon },
  run_browser_testing: { humanReadableName: "Running browser testing", icon: ComputerDesktopIcon },
  browser_testing_result: {
    humanReadableName: "Browser testing result",
    icon: ComputerDesktopIcon,
  },
  browser_testing_failure: {
    humanReadableName: "Browser testing failure",
    icon: ExclamationCircleIcon,
  },
  thinking: { humanReadableName: "Thinking", icon: LightBulbIcon },
  external_check_warning: {
    humanReadableName: "Automated checks warning",
    icon: ExclamationCircleIcon,
  },
  max_steps_reached: { humanReadableName: "Max steps reached", icon: ExclamationCircleIcon },
  self_failure: { humanReadableName: "Failed to finish", icon: ExclamationCircleIcon },
  self_review_success: { humanReadableName: "Finished self review", icon: CheckIcon },
  self_review_fail: { humanReadableName: "Failed self review", icon: ExclamationCircleIcon },
  generated_advice: { humanReadableName: "Generated advice", icon: PencilSquareIcon },
  retrieved_pr_insights: {
    humanReadableName: "Retrieved insights from memory",
    icon: CogIcon,
  },
  generated_unknowns: {
    humanReadableName: "Identified unknowns",
    icon: ClipboardDocumentListIcon,
  },
  subtask_results: {
    humanReadableName: "Investigated unknowns",
    icon: ClipboardDocumentCheckIcon,
  },
  // Additional advanced tools for subtask agent
  contextual_search_file_paths: {
    humanReadableName: "Searched file paths",
    icon: MagnifyingGlassCircleIcon,
  },
  get_symbol_definition: {
    humanReadableName: "Retrieved symbol definitions",
    icon: DocumentTextIcon,
  },
  get_symbol_usage: {
    humanReadableName: "Retrieved symbol usages",
    icon: DocumentTextIcon,
  },
  find_symbol_definition: {
    humanReadableName: "Found symbol definitions",
    icon: DocumentTextIcon,
  },
  find_files: {
    humanReadableName: "Found files",
    icon: DocumentDuplicateIcon,
  },
  subtask_observation: {
    humanReadableName: "Observations",
    icon: EyeIcon,
  },
};

const testingToolNameMap = {
  open_url: { humanReadableName: "Opened URL", icon: GlobeAmericasIcon },
  click: { humanReadableName: "Clicked element", icon: CursorArrowRippleIcon },
  set_value: { humanReadableName: "Set input value", icon: PencilSquareIcon },
  finish_testing: { humanReadableName: "Finished testing", icon: CheckIcon },
  failed_testing: { humanReadableName: "Failed testing", icon: ExclamationCircleIcon },
  upload_video: { humanReadableName: "Uploaded video", icon: ArrowUpOnSquareIcon },
  thinking: { humanReadableName: "Thinking", icon: LightBulbIcon },
};

const ADMIN_ONLY_TOOLS: (keyof typeof codeToolNameMap | keyof typeof testingToolNameMap)[] = [
  "update_plan",
];

export interface IAgentLog {
  timestamp: number;
  type: "tool" | "observation";
  toolName?: string;
  thought?: string;
  observation?: string;
  toolArgs?: Record<string, unknown>;

  // This exists when FE merges a nested run into the main log (e.g. automated feedback run into main generate pull request run)
  nestedLogs?: IAgentLog[];
}

interface IProps {
  task: GetTaskWithAllRunsQuery["task"];

  taskStatus: TaskStatus;
}

const addAutomatedFeedbackNestedLogs = (
  agentLogs: IAgentLog[],
  automatedFeedbackRuns: (
    | GetTaskWithAllRunsQuery["task"]["allGeneratePullRequestRuns"][number]["allExternalCheckRuns"][number]["allAutomatedFeedbackRuns"][number]
    | GetTaskWithAllRunsQuery["task"]["allGeneratePullRequestRuns"][number]["activePullRequest"]["allAddressCodeReviewRuns"][number]["allExternalCheckRuns"][number]["allAutomatedFeedbackRuns"][number]
  )[],
): IAgentLog[] => {
  return map(agentLogs, (agentLog) => {
    // If nested logs already exist, don't add them again
    if (!isEmpty(agentLog.nestedLogs)) {
      return agentLog;
    }

    const automatedFeedbackRunId = agentLog.toolArgs?.automatedFeedbackRunId;
    if (agentLog.toolName === "read_error_logs" && isString(automatedFeedbackRunId)) {
      const automatedFeedbackRun = find(automatedFeedbackRuns, { id: automatedFeedbackRunId });
      if (automatedFeedbackRun) {
        return {
          ...agentLog,
          nestedLogs: automatedFeedbackRun?.logs || [],
        };
      }
    }
    return agentLog;
  });
};

export const TaskAgentLog = ({ task, taskStatus }: IProps) => {
  const { selectedClient, user } = useAppContext();

  // Disable agent testing for now
  const isAgentTestingEnabled = includes(selectedClient?.features, ClientFeatures.AgentTesting);
  const testingAgentLogs = [];

  const [isTaskDetailsVisible, setIsTaskDetailsVisible] = useState(true);

  const [selectedGeneratePullRequestRunId, setSelectedGeneratePullRequestRunId] = useState<
    string | null
  >(task?.activeGeneratePullRequestRun?.id);

  const generatePullRequestRunOptions = useMemo(() => {
    return map(sortBy(task?.allGeneratePullRequestRuns, "createdAt"), (generatePullRequestRun) => {
      return {
        id: generatePullRequestRun.id,
        status: generatePullRequestRun.status,
      };
    });
  }, [task]);

  const filterAgentLogs = useCallback(
    (agentLogs: IAgentLog[]) => {
      return filter(agentLogs, (log) => {
        if (user.isAdmin) {
          return true;
        }

        if (includes(ADMIN_ONLY_TOOLS, log.toolName)) {
          return false;
        }

        return true;
      });
    },
    [user.isAdmin],
  );

  const {
    selectedGeneratePullRequestRun,
    addressCodeReviewRuns,
  }: {
    selectedGeneratePullRequestRun?: {
      logs: IAgentLog[];
      status: GeneratePullRequestRunStatus;
    };
    addressCodeReviewRuns?: {
      id: string;
      logs: IAgentLog[];
      status: AddressCodeReviewRunStatus;
    }[];
  } = useMemo(() => {
    if (!selectedGeneratePullRequestRunId || !task) {
      return {};
    }

    const generatePullRequestRun = find(task?.allGeneratePullRequestRuns, {
      id: selectedGeneratePullRequestRunId,
    });

    if (!generatePullRequestRun) {
      return {};
    }

    const generatePullRequestRunLogs = addAutomatedFeedbackNestedLogs(
      generatePullRequestRun?.logs || [],
      flatMap(
        generatePullRequestRun?.allExternalCheckRuns,
        (externalCheckRun) => externalCheckRun.allAutomatedFeedbackRuns,
      ),
    );
    const generatePullRequestRunStatus = generatePullRequestRun.status;

    const addressCodeReviewRuns =
      map(
        sortBy(generatePullRequestRun?.activePullRequest?.allAddressCodeReviewRuns, "createdAt"),
        (addressCodeReviewRun) => {
          return {
            id: addressCodeReviewRun.id,
            logs: addAutomatedFeedbackNestedLogs(
              addressCodeReviewRun.logs,
              flatMap(
                addressCodeReviewRun.allExternalCheckRuns,
                (externalCheckRun) => externalCheckRun.allAutomatedFeedbackRuns,
              ),
            ),
            status: addressCodeReviewRun.status,
          };
        },
      ) || [];

    return {
      selectedGeneratePullRequestRun: {
        logs: generatePullRequestRunLogs,
        status: generatePullRequestRunStatus,
      },
      addressCodeReviewRuns,
    };
  }, [selectedGeneratePullRequestRunId, task]);

  const [isCodeGenerationAgentLogVisible, setIsCodeGenerationAgentLogVisible] = useState(false);

  const [visibleAddressingCodeReviewAgentLogs, setVisibleAddressingCodeReviewAgentLogs] = useState<
    string[]
  >([]);

  useEffect(() => {
    if (!selectedGeneratePullRequestRun) {
      return;
    }

    setIsCodeGenerationAgentLogVisible(
      selectedGeneratePullRequestRun.status === GeneratePullRequestRunStatus.Running,
    );

    setVisibleAddressingCodeReviewAgentLogs(
      map(
        filter(
          addressCodeReviewRuns,
          (addressCodeReviewRun) =>
            addressCodeReviewRun.status === AddressCodeReviewRunStatus.Running,
        ),
        (addressCodeReviewRun) => addressCodeReviewRun.id,
      ),
    );
  }, [selectedGeneratePullRequestRun]);

  const investigationAgentLogs = task.activeInvestigationRun?.logs || [];
  const investigationRunStatus = task.activeInvestigationRun?.status;
  const [isInvestigationAgentLogVisible, setIsInvestigationAgentLogVisible] = useState(
    investigationRunStatus === InvestigationRunStatus.Running,
  );

  // Not implemented yet
  const activeTestingRunStatus = TestingRunStatus.Completed;
  const [isTestingAgentLogVisible, setIsTestingAgentLogVisible] = useState(false);

  const [isImageModalOpen, setIsImageModalOpen] = useState(false);
  const [imageModalUrl, setImageModalUrl] = useState("");

  const [description, descriptionHasImages] = useMemo(() => {
    if (size(task?.metadata?.images) === 0) {
      return [task?.metadata?.description, false];
    }

    let description = task?.metadata?.description;
    let descriptionHasImages = false;
    const taskImages = task?.metadata?.images || [];
    for (let imageIndex = 0; imageIndex < size(taskImages); imageIndex++) {
      const imageUrlPlaceholder = `Image URL index ${imageIndex}`;
      if (includes(description, imageUrlPlaceholder)) {
        descriptionHasImages = true;
        description = replace(description, imageUrlPlaceholder, taskImages[imageIndex]);
      }
    }
    return [description, descriptionHasImages];
  }, [task?.metadata?.description, task?.metadata?.images]);

  return (
    <>
      <div className="flow-root">
        <div
          className={clsx(
            "-ml-4 cursor-pointer",
            isTaskDetailsVisible ? "font-semibold" : "font-normal",
          )}
          onClick={() => setIsTaskDetailsVisible(!isTaskDetailsVisible)}
        >
          <h3 className="text-md font-medium inline-block">
            Issue details
            <IssueStatus status={taskStatus} className="ml-2" />
            {isTaskDetailsVisible ? (
              <ChevronDownIcon
                className="ml-1 mb-0.5 h-6 w-6 text-gray-400 inline-block"
                aria-hidden="true"
              />
            ) : (
              <ChevronRightIcon
                className="ml-1 mb-0.5 h-6 w-6 text-gray-400 inline-block"
                aria-hidden="true"
              />
            )}
          </h3>
        </div>
        {isTaskDetailsVisible && (
          <div className="mt-4">
            {task?.metadata?.title && (
              <div className="mt-2 text-sm text-gray-500">
                <p className="font-semibold">Title</p>
                <p>{task?.metadata?.title}</p>
              </div>
            )}
            {task?.type && (
              <div className="mt-2 text-sm text-gray-500">
                <p className="font-semibold">Type</p>
                <p>{HUMAN_READABLE_TASK_TYPES[task?.type]}</p>
              </div>
            )}
            {task?.metadata?.description && (
              <div className="mt-2 text-sm text-gray-500">
                <p className="font-semibold">Description</p>
                <div className="mt-2 prose max-w-none prose-headings:text-sm text-sm prose-img:max-h-40">
                  <CustomMarkdown>{description}</CustomMarkdown>
                </div>
              </div>
            )}
            {task?.metadata?.location && (
              <div className="mt-2 text-sm text-gray-500">
                <p className="font-semibold">Location</p>
                <p>{task?.metadata?.location}</p>
              </div>
            )}
            {!isEmpty(task?.metadata?.images) && (
              <div className="mt-2 text-sm text-gray-500">
                <p className="font-semibold">Images</p>
                <div className="flex space-x-4">
                  {map(task?.metadata?.images, (image, index) => (
                    <div
                      onClick={() => {
                        setImageModalUrl(image);
                        setIsImageModalOpen(true);
                      }}
                      key={index}
                      className="relative h-20 w-20 rounded-lg hover:cursor-pointer"
                    >
                      <img
                        src={image}
                        alt={`uploaded-image-${index}`}
                        className="h-full w-full object-cover rounded-lg"
                      />
                    </div>
                  ))}
                </div>
              </div>
            )}
            {task?.externalTicketMetadata?.identifier && task?.externalTicketMetadata?.url && (
              <div className="mt-2 text-sm text-gray-500">
                <p className="font-semibold">External issue</p>
                <a
                  href={task?.externalTicketMetadata?.url}
                  target="_blank"
                  rel="noreferrer"
                  className="text-purple-600 hover:text-purple-800 hover:underline"
                >
                  {task?.externalTicketMetadata?.identifier}
                </a>
              </div>
            )}
            {task?.repo?.name && (
              <div className="mt-2 text-sm text-gray-500">
                <p className="font-semibold">Repository</p>
                {user?.isAdmin ? (
                  <a
                    href={`${getAppUrl()}/app/admin?repoId=${task?.repo?.id}&showTree=1`}
                    target="_blank"
                    rel="noreferrer"
                    className="text-purple-600 hover:text-purple-800 hover:underline"
                  >
                    {task?.repo?.name}
                  </a>
                ) : (
                  <p>{task?.repo?.name}</p>
                )}
              </div>
            )}
            {task?.activeGeneratePullRequestRun?.activePullRequest?.externalUrl && (
              <div className="mt-2 text-sm text-gray-500">
                <div className="mt-4 mb-4 border-t border-gray-200"></div>
                <p className="font-semibold">Pull request</p>
                <a
                  href={task?.activeGeneratePullRequestRun?.activePullRequest?.externalUrl}
                  target="_blank"
                  rel="noreferrer"
                  className="text-purple-600 hover:text-purple-800 hover:underline"
                >
                  {task?.activeGeneratePullRequestRun?.activePullRequest?.externalUrl}
                </a>
              </div>
            )}
            {task?.activeGeneratePullRequestRun?.activePullRequest?.dynamicEnvUrl && (
              <div className="mt-2 text-sm text-gray-500">
                <p className="font-semibold">Preview URL</p>
                <a
                  href={task?.activeGeneratePullRequestRun?.activePullRequest?.dynamicEnvUrl}
                  target="_blank"
                  rel="noreferrer"
                  className="text-purple-600 hover:text-purple-800 hover:underline"
                >
                  {task?.activeGeneratePullRequestRun?.activePullRequest?.dynamicEnvUrl}
                </a>
              </div>
            )}
            {!task?.activeGeneratePullRequestRun?.activePullRequest?.externalUrl &&
              task?.activeGeneratePullRequestRun?.branchName &&
              getGeneratePullRequestRunBranchUrl(task) && (
                <div className="mt-2 text-sm text-gray-500">
                  <div className="mt-4 mb-4 border-t border-gray-200"></div>
                  <p className="font-semibold">Branch</p>
                  <a
                    href={getGeneratePullRequestRunBranchUrl(task)}
                    target="_blank"
                    rel="noreferrer"
                    className="text-purple-600 hover:text-purple-800 hover:underline"
                  >
                    {task?.activeGeneratePullRequestRun?.branchName}
                  </a>
                </div>
              )}
          </div>
        )}
        {investigationRunStatus && (
          <>
            <div
              className={clsx(
                "mt-8 -ml-4 cursor-pointer",
                isInvestigationAgentLogVisible ? "font-semibold" : "font-normal",
              )}
              onClick={() => setIsInvestigationAgentLogVisible(!isInvestigationAgentLogVisible)}
            >
              <h3 className="text-md font-medium inline-block">
                Investigation
                {investigationRunStatus && (
                  <span
                    className={clsx(
                      "ml-2 inline-flex items-center rounded-md bg-green-50 px-2 py-1 text-xs font-medium text-green-700 ring-1 ring-inset ring-green-600/20",
                      {
                        "bg-purple-50 text-purple-700 ring-purple-600/20": includes(
                          [InvestigationRunStatus.Running],
                          investigationRunStatus,
                        ),
                        "bg-neutral-50 text-neutral-700 ring-neutral-600/20": includes(
                          [
                            InvestigationRunStatus.SelfFailure,
                            InvestigationRunStatus.InternalFailure,
                          ],
                          investigationRunStatus,
                        ),
                        "bg-yellow-50 text-yellow-700 ring-yellow-600/20": includes(
                          [InvestigationRunStatus.Pending],
                          investigationRunStatus,
                        ),
                        "bg-green-50 text-green-700 ring-green-600/20": includes(
                          [InvestigationRunStatus.Completed],
                          investigationRunStatus,
                        ),
                      },
                    )}
                  >
                    {join(split(investigationRunStatus, "_"), " ")}
                  </span>
                )}
                {isInvestigationAgentLogVisible ? (
                  <ChevronDownIcon
                    className="ml-1 mb-0.5 h-6 w-6 text-gray-400 inline-block"
                    aria-hidden="true"
                  />
                ) : (
                  <ChevronRightIcon
                    className="ml-1 mb-0.5 h-6 w-6 text-gray-400 inline-block"
                    aria-hidden="true"
                  />
                )}
              </h3>
            </div>
            {isInvestigationAgentLogVisible && (
              <>
                {investigationRunStatus !== InvestigationRunStatus.Running &&
                size(investigationAgentLogs) === 0 ? (
                  <div className="mt-4 text-sm text-gray-500">
                    <p>There are no investigation logs for this issue.</p>
                  </div>
                ) : (
                  <AgentLogsList
                    agentLogs={investigationAgentLogs}
                    isRunning={investigationRunStatus === InvestigationRunStatus.Running}
                    toolMap={codeToolNameMap}
                  />
                )}
              </>
            )}
          </>
        )}

        <>
          <div
            className={clsx(
              "-ml-4 cursor-pointer mt-8",
              isCodeGenerationAgentLogVisible ? "font-semibold" : "font-normal",
            )}
            onClick={() => setIsCodeGenerationAgentLogVisible(!isCodeGenerationAgentLogVisible)}
          >
            <h3 className="text-md font-medium inline-block">
              Code generation
              {size(generatePullRequestRunOptions) > 1 && (
                <select
                  value={selectedGeneratePullRequestRunId}
                  onChange={(e) => {
                    setSelectedGeneratePullRequestRunId(e.target.value);
                  }}
                  className="ml-3 mr-2 p-1 pl-2 border rounded-md text-xs w-[10rem]"
                  onClick={(e) => e.stopPropagation()}
                >
                  {map(generatePullRequestRunOptions, (generatePullRequestRun, index) => {
                    const isActiveRun =
                      generatePullRequestRun.id === task.activeGeneratePullRequestRun?.id;
                    return (
                      <option key={generatePullRequestRun.id} value={generatePullRequestRun.id}>
                        {`${getNumberWithOrdinal(index + 1)} attempt${
                          isActiveRun ? " (current)" : ""
                        }`}
                      </option>
                    );
                  })}
                </select>
              )}
              {selectedGeneratePullRequestRun?.status && (
                <span
                  className={clsx(
                    "ml-2 inline-flex items-center rounded-md bg-green-50 px-2 py-1 text-xs font-medium text-green-700 ring-1 ring-inset ring-green-600/20",
                    {
                      "bg-purple-50 text-purple-700 ring-purple-600/20": includes(
                        [GeneratePullRequestRunStatus.Running],
                        selectedGeneratePullRequestRun?.status,
                      ),
                      "bg-neutral-50 text-neutral-700 ring-neutral-600/20": includes(
                        [
                          GeneratePullRequestRunStatus.SelfFailure,
                          GeneratePullRequestRunStatus.InternalFailure,
                        ],
                        selectedGeneratePullRequestRun?.status,
                      ),
                      "bg-yellow-50 text-yellow-7000 ring-yellow-600/20": includes(
                        [GeneratePullRequestRunStatus.Pending],
                        selectedGeneratePullRequestRun?.status,
                      ),
                      "bg-green-50 text-green-700 ring-green-600/20": includes(
                        [GeneratePullRequestRunStatus.Completed],
                        selectedGeneratePullRequestRun?.status,
                      ),
                    },
                  )}
                >
                  {join(split(selectedGeneratePullRequestRun?.status, "_"), " ")}
                </span>
              )}
              {isCodeGenerationAgentLogVisible ? (
                <ChevronDownIcon
                  className="ml-1 mb-0.5 h-6 w-6 text-gray-400 inline-block"
                  aria-hidden="true"
                />
              ) : (
                <ChevronRightIcon
                  className="ml-1 mb-0.5 h-6 w-6 text-gray-400 inline-block"
                  aria-hidden="true"
                />
              )}
            </h3>
          </div>
          {isCodeGenerationAgentLogVisible && (
            <>
              {selectedGeneratePullRequestRun?.status !== GeneratePullRequestRunStatus.Running &&
              size(selectedGeneratePullRequestRun?.logs || []) === 0 ? (
                <div className="mt-4 text-sm text-gray-500">
                  <p>There are no code generation logs for this issue.</p>
                </div>
              ) : (
                <AgentLogsList
                  filterAgentLogs={filterAgentLogs}
                  agentLogs={selectedGeneratePullRequestRun?.logs || []}
                  isRunning={
                    selectedGeneratePullRequestRun?.status === GeneratePullRequestRunStatus.Running
                  }
                  toolMap={codeToolNameMap}
                />
              )}
            </>
          )}
        </>
        <>
          {map(addressCodeReviewRuns, (addressCodeReviewRun, index) => (
            <div key={addressCodeReviewRun.id}>
              <div
                className={clsx(
                  "mt-8 -ml-4 cursor-pointer",
                  visibleAddressingCodeReviewAgentLogs.includes(addressCodeReviewRun.id)
                    ? "font-semibold"
                    : "font-normal",
                )}
                onClick={() =>
                  setVisibleAddressingCodeReviewAgentLogs(
                    visibleAddressingCodeReviewAgentLogs.includes(addressCodeReviewRun.id)
                      ? visibleAddressingCodeReviewAgentLogs.filter(
                          (id) => id !== addressCodeReviewRun.id,
                        )
                      : [...visibleAddressingCodeReviewAgentLogs, addressCodeReviewRun.id],
                  )
                }
              >
                <h3 className="text-md font-medium inline-block">
                  {`Addressing ${getNumberWithOrdinal(index + 1)} code review`}
                  {addressCodeReviewRun.status && (
                    <span
                      className={clsx(
                        "ml-2 inline-flex items-center rounded-md bg-green-50 px-2 py-1 text-xs font-medium text-green-700 ring-1 ring-inset ring-green-600/20",
                        {
                          "bg-purple-50 text-purple-700 ring-purple-600/20": includes(
                            [AddressCodeReviewRunStatus.Running],
                            addressCodeReviewRun.status,
                          ),
                          "bg-neutral-50 text-neutral-700 ring-neutral-600/20": includes(
                            [
                              AddressCodeReviewRunStatus.SelfFailure,
                              AddressCodeReviewRunStatus.InternalFailure,
                            ],
                            addressCodeReviewRun.status,
                          ),
                          "bg-yellow-50 text-yellow-700 ring-yellow-600/20": includes(
                            [AddressCodeReviewRunStatus.Pending],
                            addressCodeReviewRun.status,
                          ),
                          "bg-green-50 text-green-700 ring-green-600/20": includes(
                            [AddressCodeReviewRunStatus.Completed],
                            addressCodeReviewRun.status,
                          ),
                        },
                      )}
                    >
                      {join(split(addressCodeReviewRun.status, "_"), " ")}
                    </span>
                  )}
                </h3>
                {visibleAddressingCodeReviewAgentLogs.includes(addressCodeReviewRun.id) ? (
                  <ChevronDownIcon
                    className="ml-1 mb-0.5 h-6 w-6 text-gray-400 inline-block"
                    aria-hidden="true"
                  />
                ) : (
                  <ChevronRightIcon
                    className="ml-1 mb-0.5 h-6 w-6 text-gray-400 inline-block"
                    aria-hidden="true"
                  />
                )}
              </div>
              {visibleAddressingCodeReviewAgentLogs.includes(addressCodeReviewRun.id) && (
                <>
                  {addressCodeReviewRun.status !== AddressCodeReviewRunStatus.Running &&
                  size(addressCodeReviewRun.logs || []) === 0 ? (
                    <div className="mt-4 text-sm text-gray-500">
                      <p>There are no code review logs yet.</p>
                    </div>
                  ) : (
                    <AgentLogsList
                      agentLogs={addressCodeReviewRun.logs || []}
                      isRunning={addressCodeReviewRun.status === AddressCodeReviewRunStatus.Running}
                      toolMap={codeToolNameMap}
                    />
                  )}
                </>
              )}
            </div>
          ))}
        </>
        {isAgentTestingEnabled && (
          <>
            <div
              className={clsx(
                "mt-8 -ml-4 cursor-pointer",
                isTestingAgentLogVisible ? "font-semibold" : "font-normal",
              )}
              onClick={() => setIsTestingAgentLogVisible(!isTestingAgentLogVisible)}
            >
              <h3 className="text-md font-medium inline-block">
                Testing
                {activeTestingRunStatus && (
                  <span
                    className={clsx(
                      "ml-2 inline-flex items-center rounded-md bg-green-50 px-2 py-1 text-xs font-medium text-green-700 ring-1 ring-inset ring-green-600/20",
                      {
                        "bg-purple-50 text-purple-700 ring-purple-600/20": includes(
                          [TestingRunStatus.Running],
                          activeTestingRunStatus,
                        ),
                        "bg-neutral-50 text-neutral-700 ring-neutral-600/20": includes(
                          [TestingRunStatus.InternalFailure],
                          activeTestingRunStatus,
                        ),
                        "bg-yellow-50 text-yellow-700 ring-yellow-600/20": includes(
                          [TestingRunStatus.Pending],
                          activeTestingRunStatus,
                        ),
                        "bg-green-50 text-green-700 ring-green-600/20": includes(
                          [TestingRunStatus.Completed],
                          activeTestingRunStatus,
                        ),
                      },
                    )}
                  >
                    {join(split(activeTestingRunStatus, "_"), " ")}
                  </span>
                )}
              </h3>
              {isTestingAgentLogVisible ? (
                <ChevronDownIcon
                  className="ml-1 mb-0.5 h-6 w-6 text-gray-400 inline-block"
                  aria-hidden="true"
                />
              ) : (
                <ChevronRightIcon
                  className="ml-1 mb-0.5 h-6 w-6 text-gray-400 inline-block"
                  aria-hidden="true"
                />
              )}
            </div>
            {/* {isTestingAgentLogVisible && (
              <>
                {!isAgentTestingEnabled ? (
                  <div className="mt-4 text-sm text-gray-500">
                    <p>
                      Automatic AI testing is not enabled for your org. Please contact your Tusk rep
                      if you would like to inquire about pricing.
                    </p>
                  </div>
                ) : (
                  <>
                    {activeTestingRunStatus !== TestingRunStatus.Running &&
                    size(testingAgentLogs) === 0 ? (
                      <div className="mt-4 text-sm text-gray-500">
                        <p>
                          There are no testing logs for this issue yet. Tusk will begin testing the
                          pull request if a dynamic environment url is added in a comment to the
                          pull request.
                        </p>
                      </div>
                    ) : (
                      <AgentLogsList
                        agentLogs={testingAgentLogs}
                        isRunning={activeTestingRunStatus === TestingRunStatus.Running}
                        toolMap={testingToolNameMap}
                      />
                    )}
                  </>
                )}
              </>
            )} */}
          </>
        )}
      </div>
      <ImageModal
        modalOpen={isImageModalOpen}
        setModalOpen={setIsImageModalOpen}
        imageUrl={imageModalUrl}
      />
    </>
  );
};
